import axios from "axios";
import store from "@/store/index.js"
//const API = process.env.NODE_ENV == "development" ? "http://192.168.112.114:5679/licapi" : "https://service.elektroniskaskartes.lv/scapi";
const API = process.env.NODE_ENV == "development" ? "http://192.168.112.114:5679/licapi" : "/licapi";

const apiaxios = axios.create({
    crossDomain: true,
    baseURL: API,
});

apiaxios.interceptors.request.use((config) => {
    const token = sessionStorage.getItem("token")
    if (token) config.headers["Authorization"] = 'Bearer ' + token;
    return config;
}, (error) => {
    return Promise.reject(error);
});

const api = {
    api: API,
    ping() {
        return new Promise((resolve, reject) => {
            this.apiGetRequest("ping").then(response => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    login(data) {
        sessionStorage.clear("token");
        return new Promise((resolve, reject) => {
            this.apiPostRequest("dmz/login", data, {}).then(response => {
                sessionStorage.setItem("token", response.token);
                this.$vm.$store.commit("setlogged", true);
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    logout() {
        sessionStorage.removeItem("token");
        this.$vm.$store.commit("setlogged", false);
    },
    saveTariff(tariffs) {
        return this.apiPostRequest("v1/cms/tariffs", tariffs);
    },
    // HOME PAGE

    apiMultipartManyPostRequest(uri, data, file, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (typeof data == "undefined") data = null;
        let formdata = new FormData();
        formdata.append("data", JSON.stringify(data));
        if (file) {
            for (let k in file) {
                formdata.append("file", file[k], k);
            }
        }
        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            apiaxios.post(uri, formdata, {
                params: pars,
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token")
                }
                // headers: this.$vm.$store.getters["session/bearer"]
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                this.baseReject(error);
                reject(error);
            });
        })
    },
    apiMultipartPostRequest(uri, data, file, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (typeof data == "undefined") data = null;
        let formdata = new FormData();
        formdata.append("data", encodeURIComponent(JSON.stringify(data)));
        if (file) {
            formdata.append("file", file);
        }
        //console.log(formdata)
        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            apiaxios.post(uri, formdata, {
                params: pars,
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token")
                }
                // headers: this.$vm.$store.getters["session/bearer"]
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                this.baseReject(error);
                reject(error);
            });
        })
    },
    apiPostRequest(uri, data, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (typeof data == "undefined") data = null;
        // pars.headers = {
        //     Authorization: "Bearer " + sessionStorage.getItem("token")
        // };

        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            apiaxios.post(uri, data, pars).then(response => {
                resolve(response.data);
            }).catch(error => {
                this.baseReject(error);
                reject(error);
            });
        })
    },
    apiGetRequest(uri, pars, cache = false) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (!cache) pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            apiaxios.get(uri, {
                params: pars,
                // headers: {
                //     Authorization: "Bearer " + sessionStorage.getItem("token")
                // }
                // headers: this.$vm.$store.getters["session/bearer"]
            }, ).then(response => {
                resolve(response.data);
            }).catch(error => {
                this.baseReject(error);
                // this.$vm.$store.commit("session/clearUser");
                // this.$vm.$router.push("/login");
                reject(error);
            });
        })
    },
    apiDeleteRequest(uri, data, pars) {
        if (typeof pars == "undefined" || pars == null) pars = {};
        if (typeof data == "undefined") data = null;
        pars.prevcache = new Date().getTime();
        return new Promise((resolve, reject) => {
            apiaxios.delete(uri, {
                data: data,
                params: pars,
                // headers: this.$vm.$store.getters["session/bearer"]
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
                this.baseReject(error);
                // this.$vm.$store.commit("session/clearUser");
                // this.$vm.$router.push("/login");
                reject(error);
            });
        })
    },
    upper_menu: [{
            text: "clients",
            fmenu: false,
            role: "USER",
            menu: [{
                text: "clients",
                role: "USER",
                route: "/clients",
            }, {
                text: "keys",
                role: "USER",
                route: "/keys",
            }],
        }, {
            text: "abnmnt",
            fmenu: false,
            role: "USER",
            menu: [{
                text: "services",
                role: "USER",
                route: "/clserv",
            }, {
                text: "abonements",
                role: "USER",
                route: "/abonements",
            }, {
                text: "tariffs",
                role: "USER",
                route: "/tariffs",
            }, {
                text: "dicts",
                role: "USER",
                route: "/dicts",
            }],
        }, {
            text: "clubsett",
            role: "USER",
            side: false,
            fmenu: false,
            menu: [{
                text: "rooms",
                role: "USER",
                route: "/rooms",
            }, {
                text: "coachs",
                role: "USER",
                route: "/coachs",
            }, {
                text: "workouts",
                role: "USER",
                route: "/workouts",
            }],
        }, {
            text: "reportsm",
            role: "USER",
            side: false,
            fmenu: false,
            menu: [{
                text: "soldabonements",
                role: "USER",
                route: "/soldabonements",
            }, {
                text: "regvis",
                role: "USER",
                route: "/regvis",
            }, {
                text: "regservs",
                role: "USER",
                route: "/regservs",
            }, {
                text: "visits",
                role: "USER",
                route: "/visits",
            }],
        }, {
            text: "reports",
            role: "USER",
            side: false,
            route: "/reports",
        },
        {
            text: "clubs",
            role: "USER",
            route: "/clubs",
            side: true,
        }, {
            divider: true,
        }, {
            text: "users",
            route: "/users",
            role: "USER",
            side: true,
        },
        {
            text: "groups",
            route: "/groups",
            role: "USER",
            side: true,
        }, {
            text: "workstations",
            route: "/workstations",
            role: "USER",
            side: true,
        }, {
            divider: true,
        }, {
            text: "events",
            route: "/events",
            role: "ADMIN",
            side: true,
        }, {
            text: "activity",
            route: "/actlog",
            role: "USER",
            side: true,
        }, {
            text: "logs",
            route: "/logs",
            role: "USER",
            side: true,
        }, {
            divider: true,
        }
    ],

    copyText(el) {
        try {
            let range = document.createRange();
            range.selectNode(document.getElementById(el));
            window.getSelection().removeAllRanges(); // clear current selection
            window.getSelection().addRange(range); // to select text
            document.execCommand("copy");
            window.getSelection().removeAllRanges(); // to deselect
        } catch (error) {
            console.log(error);
        }

    },
    //TAG supprot for store

    updateTags(state, inInfo = true) {
        try {
            state.items.forEach((e) => {
                if (inInfo && e.info.tags) {
                    state.tags = state.tags.concat(e.info.tags);
                } else if (e.tags) {
                    state.tags = state.tags.concat(e.tags);
                }
            });
        } catch (error) {
            console.log(error);
        }
        state.tags = state.tags.filter((value, index, self) => {
            return self.indexOf(value) === index;
        }).sort();
    },

    updateTag(state, item, inInfo = true) {
        try {
            const tags = inInfo ? item.info.tags : item.tags;
            if (!tags) return;
            tags.forEach((e) => {
                if (state.tags.indexOf(e) != -1) return;
                state.tags.push(e);
            });
        } catch (error) {
            console.log(error);
        }
        state.tags = state.tags.filter((value, index, self) => {
            return self.indexOf(value) === index;
        }).sort();
    },
    // security 
    testRoles(roles) {
        try {
            return roles.split(",").indexOf(store.state.session.sessionData.group.role) != -1;
        } catch (error) {
            console.log("access error: " + store.state.session.sessionData.group.role, roles)
            return false;
        }
    },
    // api ui functions
    copy(i, def) {
        try {
            return JSON.parse(JSON.stringify((i && i.idx) ? {
                ...i
            } : {
                ...def
            }));
        } catch (error) {
            return {};
        }
    },
    isLogged() {
        try {
            return this.$vm.$store.getters["isLogged"]
        } catch (error) {
            return false;
        }
    },
    toDate(from, period) {
        try {
            const dt = this.$vm.$moment(from);
            switch (period.duration.type) {
                case "m":
                    return dt.add(period.duration.value, "month");
                case "d":
                    return dt.add(period.duration.value, "day");
                case "h":
                    return dt.add(period.duration.value, "hour");
                default:
                    return "";
            }
        } catch (error) {
            return "";
        }
    },
    getSavedLocaleAsStr() {
        return localStorage.getItem("lang");
    },
    baseReject(error) {
        let m = error;
        if (error.response && error.response.status == 401) {
            this.$vm.$router.push("/login");
            return;
        } else if (error.response && error.response.status == 403) {
            this.$vm.$router.push("/");
            m = "No access";
        } else {
            if (error.response && error.response.data) m = error.response.data;
        }
        this.showMessage(m, true);
    },
    showMessage(text, isError = true) {
        this.$vm.$root.$emit("showmsg", {
            msg: text,
            color: isError ? "error" : "success"
        });
    },
    setLocale(v) {
        localStorage.setItem("lang", v);
        this.$vm.$i18n.locale = v;
    },
    setVm(vm) {
        this.$vm = vm;
        this.$vm.$i18n.locale = this.getSavedLocaleAsStr();
    },
}
export default api;