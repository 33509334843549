import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import filters from './filters'
import store from './store'
import api from "@/api.js";
import i18n from './i18n'

Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.use(filters);

const vm = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

api.setVm(vm);

Vue.prototype.$rules = {
  required: (value) => !!value || vm.$t("error.required"),
  nonzero: (value) => value>0 || vm.$t("error.required"),
  counter: (value) => value.length <= 20 || "Max 20 characters",
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !value || pattern.test(value) || vm.$t("error.email");
  },
};