import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged: false
  },
  getters: {
    isLogged: (state) => {
      if(!state.logged) state.logged = (sessionStorage.getItem("token") != null);
      return state.logged;
    }
  },
  mutations: {
    setlogged: (state, payload) => {
      state.logged = payload;
    },
  },
  actions: {

  },
  modules: {}
})