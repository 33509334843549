import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/buy',
    name: 'Buy',
    component: () => import('../views/BuyLicense.vue')
  },
  {
    path: '/result/:id',
    name: 'BuyResult',
    component: () => import('../views/BuyResult.vue')
  },
  {
    path: '/ws',
    name: 'workstation',
    component: () => import('../views/Workstations.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/users',
    name: 'cmsusers',
    component: () => import('../views/CmsUsers.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/licenses',
    name: 'licenses',
    component: () => import('../views/Licenses.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('../views/Tickets.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/liclog',
    name: 'licenseslog',
    component: () => import('../views/LicensesLog.vue'),
    meta: {
      auth: true,
    },
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(function (to, from, next) {
  if (to.meta.auth) {
    if (!sessionStorage.getItem("token")) {
      if (from != "/login") next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router