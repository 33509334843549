<template>
  <v-app id="app">
    <v-app-bar app elevation="3" class="blue lighten-5">
      <v-img src="mansklubs_logo.png" max-width="40px"></v-img>
      <i18n path="title" />
      <v-spacer></v-spacer>
      <template v-if="isLogged">
        <v-btn icon to="/users">
          <v-icon>mdi-account-multiple</v-icon>
        </v-btn>
        <v-btn icon @click="d_tariff=true">
          <v-icon>mdi-cash-multiple</v-icon>
        </v-btn>
        <v-btn v-for="m in menu" :key="m" text :to="m">
          <v-icon v-text="$t(`icons.${m}`)"></v-icon>
          <span v-text="$t(`menu.${m}`)"></span>
        </v-btn>
      </template>
      <v-btn icon @click="logswitch" class="mr-10">
        <v-icon>{{isLogged?"mdi-location-exit":"mdi-location-enter"}}</v-icon>
      </v-btn>
      <SelectLocale class="justify-end" />
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="orange lighten-5">
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="fill-height">
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
    <v-snackbar v-model="show" :color="mColor" transition="v-scroll-x-transition" shaped>
      {{ mText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="show=false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <EditTariffDialog v-model="d_tariff" />
  </v-app>
</template>
<script>
import SelectLocale from "@/components/Select-locale";
import EditTariffDialog from "@/components/EditTariffDialog";

export default {
  name: "LicensesCms",
  components: {
    SelectLocale,
    EditTariffDialog,
  },

  data: () => ({
    show: false,
    d_tariff: false,
    mText: "",
    menu: ["ws", "licenses", "liclog", "tickets"],
    mColor: "blue lighten-1",
  }),
  computed: {
    isLogged() {
      return this.$store.getters["isLogged"];
    },
  },
  methods: {
    logswitch() {
      if (this.isLogged) {
        this.$api.logout();
        if (this.$route.path != "/") this.$router.push("/");
      } else {
        if (this.$route.path == "/login") this.$router.push("/");
        else this.$router.push("/login");
      }
    },
    showSnap(e) {
      this.mText = e.msg;
      if (e.color) this.mColor = e.color;
      this.show = true;
    },
  },
  mounted() {
    this.$root.$on("showmsg", (e) => {
      this.showSnap(e);
    });
  },
};
</script>

<style lang="scss">
.v-data-table .v-data-table-header {
  background-color: #bddddd63;
}
.v-data-table .v-data-footer {
  background-color: #bddddd63;
}
.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #fdf9d4;
}
.v-data-table tr:nth-of-type(even) {
  background-color: #ecebdc;
}
.fill-width {
  width: 100%;
}
.page-sheet {
  height: 100%;
  width: 100%;
}
</style>
