<template>
  <v-dialog v-model="isActive" persistent width="600">
    <v-card class="yellow lighten-5">
      <v-card-title>
        <i18n path="tariff.title" />
        <v-spacer></v-spacer>
        <v-btn icon color="error" @click="$emit('input', false)">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <template v-for="(v,k) in tariffs">
            <v-col align-self="center" :key="k">
              <v-img :src="'/img/'+k+'.png'" width="60px" class="img-license-img"></v-img>
              <div class="mt-4 text-center">
                <i18n path="tariff.pinfo" class="text-h5 font-weight-black">
                  <template #days>{{tariffs[k].days}}</template>
                  <template #price>{{tariffs[k].price | currency}}</template>
                </i18n>
              </div>
              <div class="mx-4 tariff-edit">
                <v-text-field v-model="tariffs[k].price" suffix="cents"></v-text-field>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click.stop="saveTariff">
          <i18n path="btn.ok" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TariffDialog",
  props: { value: Boolean },
  data() {
    return {
      tariffs: {},
    };
  },
  computed: {
    isActive() {
      return this.value;
    },
  },
  methods: {
    saveTariff() {
      this.$api.saveTariff(this.tariffs).then((response) => {
        if (response) this.loadTariff();
      });
    },
    loadTariff() {
      fetch(this.$api.api + "/v1/license/tariffs")
        .then((r) => r.json())
        .then((d) => {
          this.tariffs = { ...d };
        });
    },
  },
  mounted() {
    this.loadTariff();
  },
};
</script>

<style scoped>
.img-license {
  align-self: center;
}
.img-license-img {
  /* -webkit-box-shadow: 5px 5px 15px 5px #000000;
  box-shadow: 5px 5px 15px 5px #000000; */
  cursor: pointer;
  margin: auto;
}
.tariff-edit >>> input {
  text-align: right;
}
</style>